import React, {Fragment, useEffect, useState, useRef} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import BuildIcon from '@material-ui/icons/Build'
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import {FabricCanvas} from 'product-design-tool'
import {formatCurrencyString, useShoppingCart} from '../context'

import HeaderNavbar from '../components/HeaderNavbar'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default ({ id }) => {
  const canvasRef = useRef(null)
  const [state, setProductDesign] = useState({
    design: {
      custom: {},
      product: {},
      totalPrice: 0
    },
    loading: true
  })
  const [open, setOpen] = useState(false)
  const { addItem } = useShoppingCart()

  useEffect(() => {
    // Make a New Canvas
    // addToCanvas(document.getElementById('my-image'), '', 2)
    // addToCanvas('https://res.cloudinary.com/fitouch/image/upload/v1589475273/sample.jpg', '', 2)

    // Get by design id
    // const customizeProduct = window.sessionStorage.getItem(id)
    const customizeProduct = window.sessionStorage.getItem('designX')
    setProductDesign({...state, design: JSON.parse(customizeProduct), loading: false})

    const handleResize = () => {
      console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  /*
  const checkMimeType = (event) => {
    // getting file object
    let files = event.target.files
    // define message container
    let err = ''
    // list allow mime type
    const types = ['image/png', 'image/jpeg', 'image/gif']
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every(type => files[x].type !== type)) {
        // create error message and assign to container
        err += files[x].type + ' is not a supported format\n'
      }
    }

    if (err !== '') { // if message not same old that mean has error
      event.target.value = null // discard selected file
      console.log(err)
      return false
    }
    return true
  }
  */

  /*
  const checkFileSize = (event) => {
    let files = event.target.files
    let size = 15000
    let err = ''
    for (let x = 0; x < files.length; x++) {
      if (files[x].size > size) {
        err += files[x].type + 'is too large, please pick a smaller file\n'
      }
    }
    if (err !== '') {
      event.target.value = null
      console.log(err)
      return false
    }

    return true
  }
  */

  const addToCart = async () => {
    console.log(state.design)
    // const sku = Math.random().toString(25).substr(2, 12)
    const sku = Math.random().toString(25)
    const object = await canvasRef.current.getFiles()
    // console.log('Object: ', object)
    // console.log({...state.design.product, image: object.image, metadata: {...state.design.product.metadata, files: object.files}, currency: 'USD'})

    addItem({...state.design.product, sku, image: object.image, metadata: {...state.design.product.metadata, files: object.files}, currency: 'USD'}, state.design.quantity)
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <HeaderNavbar/>
      <Box style={{padding: '1rem'}}>
        <Container maxWidth={'md'}>
          <Grid container>
            <Grid item xs={12} sm={4} align={'left'} />
            <Grid item xs={12} sm={4} align={'center'}>
              <Typography variant={'h5'}>
                {state.design.product.name}
              </Typography>
              {state.design.product.description && (
                <Typography variant={'subtitle1'}>
                  {state.design.product.description}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box alignItems={'center'} display={'flex'} justifyContent={'flex-end'}>
                <Typography variant={'h6'} style={{color: '#01b901', marginRight: 10}}>
                  {formatCurrencyString({
                    value: state.design.totalPrice,
                    currency: 'USD',
                    language: 'en-US'
                  })}
                </Typography>
                <Button variant={'contained'} color={'primary'} onClick={addToCart}>
                  Add to Cart
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {!state.loading && (
          <FabricCanvas
              ref={canvasRef}
              shape={state.design.custom['Shape']}
              height={state.design.custom.height}
              width={state.design.custom.width}
              templatePath={state.design.template ? state.design.template.canvasPath : null}
          />
      )}
      <Box marginBottom={5} marginTop={3}>
        <Container maxWidth={'md'}>
          <Grid container>
            <Grid item sm={4} align={'left'} />
            <Grid item sm={4} align={'center'} />
            <Grid item sm={4} align={'right'} />
          </Grid>
        </Container>
      </Box>
      <Container maxWidth={'md'} style={{padding: '1rem'}}>
        <Grid container spacing={5}>
          <Grid item sm={2} />
          <Grid item sm={4}>
            <Box marginBottom={2}>
              <Grid container spacing={1} direction='row' alignItems='center'>
                <Grid item>
                  <PhoneInTalkIcon />
                </Grid>
                <Grid item>
                  <Typography variant={'h6'}>
                    Need help?
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box marginBottom={2}>
              <Typography>Call us at 1.860.677.2497</Typography>
            </Box>
            <Typography>Sun. Closed</Typography>
            <Typography>Mon. - Fri. 7:00 AM-5:00 PM</Typography>
            <Typography>Sat. 8:00 AM-12:00 PM</Typography>
          </Grid>
          <Grid item sm={4}>
            <Box marginBottom={2}>
              <Grid container spacing={1} direction='row' alignItems='center'>
                <Grid item>
                  <BuildIcon />
                </Grid>
                <Grid item>
                  <Typography variant={'h6'}>
                    Graphic design services
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box marginBottom={2}>
              <Typography>
                For just $25.00, one of our designers can update this template for you within 24 hours.
              </Typography>
            </Box>
            <Link href='#'>
              Get started
            </Link>
          </Grid>
          <Grid item sm={2} />
        </Grid>
      </Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Added to cart!
        </Alert>
      </Snackbar>
    </Fragment>
  )
}
