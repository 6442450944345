import React from 'react'
import { Router } from '@reach/router'

import Layout from '../layouts'
import ProductDesign from '../components/product-design'

export default function Design () {
  return (
    <Layout>
      <Router basepath='/design'>
        <ProductDesign path='/tool/:id' />
      </Router>
    </Layout>
  )
}
